import styled from "styled-components";
import LineReveal from "../../components/Text/LineReveal/LineReveal";
import { media } from "../../utils/styled/responsive";

export const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;

  padding: 64px 16px;
  display: grid;
  grid-template-rows: repeat(2, auto);

  ${media.tablet} {
    padding: 128px 64px;
  }

  ${media.desktop} {
    grid-template-rows: unset;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const NumbersWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 48px;
  display: inline-block;
  z-index: 1;

  ${media.tablet} {
    top: 64px;
    right: 64px;
  }

  ${media.desktop} {
    top: 160px;
    right: 64px;
  }
`;
export const Email = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
`;

export const Inner = styled.div`
  ${media.desktop} {
    align-self: end;
    grid-column: 1/2;
    grid-row: 1;
  }
`;

export const Details = styled.div`
  display: grid;
  gap: 32px;

  ${media.tablet} {
    grid-auto-flow: column;
  }
`;

export const Adress = styled(LineReveal)`
  & .spacer {
    height: 0;
  }
`;

export const Header = styled.div`
  padding: 40px 0;

  ${media.tablet} {
    padding: 64px 0;
  }

  ${media.desktop} {
    padding: 80px 0;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;

  ${media.tablet} {
    width: 80%;
  }

  ${media.desktop} {
    width: 100%;
    grid-column: 2/3;
    grid-row: 1;
  }
`;

export const LineWrapper = styled.div`
  position: absolute;
  display: grid;
  place-items: end;
  width: 100%;
  height: 100%;
  pointer-events: none;
  right: 0;
  bottom: 0;

  ${media.desktop} {
    right: 0;
    bottom: 0;
  }
`;
